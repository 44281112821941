// src/api.js
import { Client, Databases } from 'appwrite';

const projectId = process.env.REACT_APP_APPWRITE_PROJECT_ID;

const client = new Client()
  .setEndpoint('https://cloud.appwrite.io/v1')
  .setProject(projectId);

const databases = new Databases(client);

const databaseId = 'primaryDB';
const businessCollectionId = 'businesses';
const feedbackCollectionId = 'feedbacks';

export const saveRatingToDatabase = async (businessId, rating) => {
  try {
    var response = await databases.createDocument(databaseId, feedbackCollectionId, 'unique()', {
        "businessUid": businessId,
        "ratingValue" : rating,
        "createdAt": new Date().toISOString(),
        "updatedAt": new Date().toISOString(),
    });
    
    return response.$id;
  } catch (error) {
    console.error('Error saving rating:', error);
    throw error;
  }
};

export const saveCommentToDatabase = async (feedbackId, comment) => {
  try {
    await databases.updateDocument(databaseId, feedbackCollectionId, feedbackId, {
      "comment": comment,
      "updatedAt": new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error saving comment:" + feedbackId , error);
    throw error;
  }
};

export const getBusinessData = async (businessId) => {
    try {

      console.log("Environment project", process.env.REACT_APP_APPWRITE_PROJECT_ID);
      console.log("Environment", process.env.NODE_ENV);
      
      const document = await databases.getDocument(databaseId, businessCollectionId, businessId);
      return document;
    } catch (error) {
      console.error('Error fetching business data:', error);
      throw error;
    }
  };