// src/RatingPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Textarea, Button, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { motion } from 'framer-motion';
import { getBusinessData, saveRatingToDatabase, saveCommentToDatabase } from './api';

const emojis = [
  { emoji: '😢', value: 1, color: 'red.500' },
  { emoji: '😞', value: 2, color: 'orange.500' },
  { emoji: '😐', value: 3, color: 'yellow.500' },
  { emoji: '😁', value: 4, color: 'green.500' },
  { emoji: '🤩', value: 5, color: 'teal.500' },
];

const RatingPage = () => {
  const { businessId } = useParams();
  const [selectedRating, setSelectedRating] = useState(null);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [positiveRatingRedirect, setPositiveRatingRedirect] = useState('');
  const [feedbackId, setFeedbackId] = useState('');
  const [thankYou, setThankYou] = useState(false);
  const [error, setError] = useState(false);
  const [businessName, setBusinessName] = useState('');

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const businessData = await getBusinessData(businessId);
        setBusinessName(businessData.name);
        setPositiveRatingRedirect(businessData.positiveRatingRedirect);
      } catch (err) {
        console.error('Error fetching business data:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessData();
  }, [businessId]);

  const handleRating = async (value) => {
    try {
      setLoading(true);
      var id = await saveRatingToDatabase(businessId, value);
      console.log('Rating saved with ID:', id);
      setFeedbackId(id);
    
      setSelectedRating(value);

      if (value > 3) {
        if (positiveRatingRedirect) {
          window.location.href = positiveRatingRedirect;
        } else {
          setThankYou(true);
        }
      }
    } catch (err) {
      console.error('Error saving rating:', err);
      alert('Uh-no! Something went wrong 😢');
    } finally {
      setLoading(false);
    }
  };

  const handleCommentSubmit = async () => {
    if (comment.length >= 5) {
      try {
        await saveCommentToDatabase(feedbackId, comment);
        setThankYou(true);
      } catch (err) {
        console.error('Error saving comment:', err);
        alert('Uh-no! Something went wrong 😢');
      }
    } else {
      alert('Comment must be at least 5 characters long.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minH="100vh" bg="gray.900" color="white">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minH="100vh" bg="gray.900" color="white">
        <Text fontSize="lg">Uh-no! Something went wrong 😢</Text>
      </Box>
    );
  }

  return (
    <Box bg="gray.900" color="white" minH="100vh" display="flex" flexDirection="column" alignItems="center" px={4}>
      <Heading as="h1" mb={6} mt={8} fontFamily="Poppins" textAlign="center">
        BravoTap 👏
      </Heading>
      <Box flex="1" display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" mb={8}>
        {selectedRating === null ? (
          <VStack spacing={6} align="center" mt={-20}>
            <Text fontSize="2xl" color="white" mb={4}>{businessName}</Text>
            <Text fontSize="lg" color="white">How happy are you with us?</Text>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <HStack spacing={4} justify="center">
                {emojis.map(({ emoji, value, color }) => (
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.3, rotate: 10, transition: { duration: 0.3 } }}
                    key={value}
                    onClick={() => handleRating(value)}
                    style={{ fontSize: '3em', cursor: 'pointer', color: color }}
                  >
                    {emoji}
                  </motion.div>
                ))}
              </HStack>
            </motion.div>
          </VStack>
        ) : thankYou ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Text fontSize="xl" mt={8}>Thank you for your feedback!</Text>
          </motion.div>
        ) : selectedRating <= 3 ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ width: '100%', maxWidth: '400px', textAlign: 'center' }}
          >
            <Textarea
              placeholder="Tell us what went wrong..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              mb={4}
              size="sm"
            />
            <Button onClick={handleCommentSubmit} size="sm" isDisabled={comment.length < 5}>Submit</Button>
          </motion.div>
        ) : null}
      </Box>
    </Box>
  );
};

export default RatingPage;
