// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import RatingPage from './RatingPage';

const theme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
});

const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
        <Routes>
          <Route path="/:businessId" element={<RatingPage />} />
        </Routes>
    </Router>
  </ChakraProvider>
);

export default App;
